<script setup lang="ts">
    // import { addSlideInUp } from '~~/composables/useScrollTrigger';
    // onMounted(() => {
    //     addSlideInUp('.union-title-container');
    // });
    // import Store from 'store';
    // const { $LOGIN_ROOT, $INDEX_ROOT } = useNuxtApp();
    // const route = useRoute();
    // const router = useRouter();
    // const token = useCookie('token').value || Store.get('token');
    // if (token) {
    //     router.replace({ path: `/${$INDEX_ROOT}` });
    // } else {
    //     router.replace({ path: `/${$LOGIN_ROOT}`, query: { ...route.query } });
    // }

    import detailStudio from '@assets/Video/home/detail_studio.mp4';
    import detailFabric from '@assets/Video/home/detail_fabric.mp4';
    import detailCloud from '@assets/Video/home/detail_cloud.mp4';
    import detailOne from '@assets/Video/home/detail_one.mp4';
    import detailMarket from '@assets/Video/home/detail_market.mp4';
    import detailMixMatch from '@assets/Video/home/detail_mixmatch.mp4';

    import detailStudioPoster from '@assets/Video/home/detail_studio.png';
    import detailFabricPoster from '@assets/Video/home/detail_fabric.jpg';
    import detailCloudPoster from '@assets/Video/home/detail_cloud.jpg';
    import detailOnePoster from '@assets/Video/home/detail_one.jpg';
    import detailMarketPoster from '@assets/Video/home/detail_market.jpg';
    import detailMixMatchPoster from '@assets/Video/home/detail_mixmatch.png';

    import { routeMapper } from '~~/composables/useRoutesMapper';
    import { ifCNLocale } from '~~/composables/useLocale.ts';
    import { matchMobile } from '~~/composables/useMatchRules.ts';
    import { ref, reactive, onMounted, onBeforeMount, computed } from 'vue';

    import i18n from '@/locales';

    const isCN = ifCNLocale();
    const { t } = i18n.global;
    const isMobile = ref(false);

    const catalogueList: Array<CataItem> = [
        {
            linkButtonText: t('global_link_button_0'),
            categoryText: 'global_home_catalogue_category_0',
            itemName: 'global_home_catalogue_item_name_0',
            itemDes: 'global_home_header_submenu_link_0_0_des2',
            itemNature: 'global_home_header_submenu_link_0_0_des',
            mediaPath: detailStudio,
            poster: detailStudioPoster,
            to: routeMapper.products.studio,
        },
        {
            linkButtonText: t('global_link_button_0'),
            categoryText: 'global_home_catalogue_category_0',
            itemName: 'global_home_catalogue_item_name_1',
            itemDes: isCN ? 'global_home_header_submenu_link_0_1_des2' : 'global_home_header_submenu_link_0_1_des3',
            itemNature: 'global_home_header_submenu_link_0_1_des',
            mediaPath: detailFabric,
            poster: detailFabricPoster,
            to: routeMapper.products.fabric,
        },
        {
            linkButtonText: t('global_link_button_0'),
            categoryText: 'global_home_catalogue_category_0',
            itemName: 'global_home_catalogue_item_name_2',
            itemDes: isCN ? 'global_home_header_submenu_link_0_2_des2' : 'global_home_header_submenu_link_0_2_des3',
            itemNature: 'global_home_header_submenu_link_0_2_des',
            mediaPath: isCN ? detailCloud : detailOne,
            poster: isCN ? detailCloudPoster : detailOnePoster,
            to: routeMapper.products.cloud,
        },
        {
            linkButtonText: t('global_link_button_0'),
            categoryText: 'global_home_catalogue_category_0',
            itemName: 'global_studio_swipper_colorTitle_6',
            itemDes: 'global_home_header_submenu_link_0_8_des3',
            itemNature: 'global_home_header_submenu_link_0_8_des',
            mediaPath: detailMixMatch,
            poster: detailMixMatchPoster,
            to: routeMapper.products.mixmatch,
        },
    ];

    const showVideoSwipper = ref(false);

    onMounted(() => {
        isMobile.value = matchMobile();

        setTimeout(() => {
            showVideoSwipper.value = true;
        }, 1000);
    });
</script>

<template>
    <div class="home-container">
        <!-- 首页轮播 -->
        <!-- <CommonComponentsHomeOlympicBanner /> -->
        <a-carousel autoplay :autoplaySpeed="7000">
            <CommonComponentsHomeVideoSwipper :class="{ hide: !showVideoSwipper }" />
            <!-- <CommonComponentsHomeOlympicBanner :class="{ hide: !showVideoSwipper }" /> -->
        </a-carousel>
        <!-- Banner -->
        <!-- <CommonComponentsHomeVideoSwipper /> -->

        <div class="global-limited-1360px-container">
            <!-- 产品目录 -->
            <CommonComponentsHomeProductCatalogue :catalogue-list="catalogueList" />
            <!-- 产品详情 -->
            <CommonComponentsHomeProductDetails :catalogue-list="catalogueList" />
        </div>
        <!-- 端到端的全链路高效流程 -->
        <CommonComponentsHomeProcedure />
        <div class="bg-light-gray">
            <div class="global-limited-1360px-container">
                <!-- 解决方案 -->
                <CommonComponentsHomeSolutions />
            </div>
        </div>
        <div class="gsap-pin-target">
            <div class="global-limited-1360px-container">
                <!-- 合作伙伴 -->
                <CommonComponentsHomePartners />
                <!-- 用户心声 -->
                <CommonComponentsHomeUserVoice />
                <!-- <CommonComponentsXScroll>
                    <template #default>
                        <div v-for="(_, index) in 5" :key="index" class="scroll-card">A</div>
                    </template>
                </CommonComponentsXScroll> -->
            </div>
            <CommonComponentsFooter />
        </div>
    </div>
</template>

<style lang="less">
    .home-container {
        background-color: #000000;
        width: 100%;
    }

    .bg-light-gray {
        overflow: hidden;
        background-color: #262626;
        padding-bottom: .convert(100px) [ @vh];
        @media (min-width: 1440px) {
            padding-bottom: .convert(30px) [ @vh];
        }
        @media (min-width: 1920px) {
            padding-bottom: .convert(10px) [ @vh];
        }
    }
    // .scroll-card {
    //     height: 30vh;
    //     width: 30vw;
    //     background: lightblue;
    //     margin-bottom: 20px;
    //     font-size: 30px;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    // }

    .solution-arrow-up,
    .solution-arrow-down {
        margin: .convert(100px) [ @vw] 0;
        height: .convert(194px) [ @vw];
        @media (max-width: @screen-mobile-width) {
            margin: 40px 0;
            height: 50px;
        }
    }
</style>
